import React, { ReactElement, useState, useMemo } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import MainMenuDrawerContainer from 'components/layout/mainMenuDrawer/mainMenuDrawerContainer';

import useLayout from 'providers/layout/layoutProvider';

import theme, { bp, color } from 'settings/theme';
import { useRouter } from 'next/router';



type Props = {};

const MainMenuStyled: React.FC<Props> = (props) => {

  const layout = useLayout();
  const router = useRouter();

  const handleHomeNavigate = (tabName: string) => {
    layout.setActiveTab(tabName);
    layout.setIsMainMenuOpen(false);
  }

  return (
    <MainMenuDrawerContainer>
      <StyledMainMenuDrawer className="mainMenuDrawer">

        <ul className="mainMenuDrawer-menu mainMenuDrawer-topMenu">
          <li>
            <button onClick={() => handleHomeNavigate('')}>Intro</button>
          </li>
          <li>
            <button onClick={() => handleHomeNavigate('gobi')}>Gobi</button>
          </li>
          <li>
            <button onClick={() => handleHomeNavigate('oasis')}>Oasis</button>
          </li>
          <li>
            <button onClick={() => handleHomeNavigate('ormuz')}>Ormuz</button>
          </li>
          <li>
            <button onClick={() => handleHomeNavigate('merchants')}>Merchants</button>
          </li>
          <li>
            <button onClick={() => handleHomeNavigate('more')}>More</button>
          </li>
        </ul>

        <ul className="mainMenuDrawer-menu mainMenuDrawer-bottomMenu">
          <li>
            <Link href="/gobi-token" onClick={() => layout.setIsMainMenuOpen(false)}>
              $GOBI <span><img src="/images/icons/icon-upright-white.svg" /></span>
            </Link>
          </li>
          <li>
            <Link href="/staking" onClick={() => layout.setIsMainMenuOpen(false)}>
              Staking <span><img src="/images/icons/icon-upright-white.svg" /></span>
            </Link>
          </li>
          <li>
            <Link href="/ceremony" onClick={() => layout.setIsMainMenuOpen(false)}>
              Ceremony <span><img src="/images/icons/icon-upright-white.svg" /></span>
            </Link>
          </li>
          <li>
            <Link href="https://twitter.com/gobi_labs" target="_blank" onClick={() => layout.setIsMainMenuOpen(false)}>
              Twitter <span><img src="/images/icons/icon-upright-white.svg" /></span>
            </Link>
          </li>
          <li>
            <Link href="https://discord.gg/gobi" target="_blank" onClick={() => layout.setIsMainMenuOpen(false)}>
              Discord <span><img src="/images/icons/icon-upright-white.svg" /></span>
            </Link>
          </li>
        </ul>

      </StyledMainMenuDrawer>
    </MainMenuDrawerContainer>
  );

};

export default MainMenuStyled;

const StyledMainMenuDrawer = styled.div<{}>`

  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 0;

  .mainMenuDrawer-menu{

    width: 100%;

    button, a{

      display: flex;
      align-items: center;
      width: 100%;
      height: 2.5rem;
      padding: 0 1.5rem;
      font-size: 1.25rem;
      background-color: transparent;
      transition: background-color 0.2s ease;

      span{

        display: flex;
        align-items: center;
        margin-left: 0.5rem;

        img{
          width: 0.75rem;
          height: 0.75rem;
          opacity: 0.5;
        }

      }

      &:hover{
        background-color: ${color.gray(0)};
      }

    }

  }

  .mainMenuDrawer-bottomMenu{

    margin-top: 1rem;

    button, a{

      color: ${color.gray(50)};

    }

  }

`
