import React, { } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import MaxWidthWrapper from 'components/layout/maxWidthWrapper';

import theme, {bp, color} from 'settings/theme';

type Props = {}

const Footer: React.FC<Props> = (props) => {

  return (
    <StyledFooter className="footer">

      <MaxWidthWrapper className="footer-wrapper">
        
        <div className="footer-top">
          <div className="footer-menu">
            <ul>
              <li><Link href="/gobi-token">$GOBI</Link></li>
              <li><Link href="/staking">Staking</Link></li>
              <li><Link href="/ceremony">Ceremony</Link></li>
              <li><Link href="https://discord.gg/gobi" target="_blank">Discord</Link></li>
              <li><Link href="https://twitter.com/gobi_labs" target="_blank">Twitter</Link></li>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-bottom-logo">
            <Link href="/">
              <img src="/images/gobi-logo-main.svg" alt="Gobi Logo" />
            </Link>
          </div>
          <div className="footer-bottom-copy">
            &copy;GOBI Labs | All Rights Reserved
          </div>
        </div>

      </MaxWidthWrapper>

    </StyledFooter>
  );

};


export default Footer;


// Footer
const StyledFooter = styled.footer<{}>`

  padding: 2rem 1.5rem;
  background-color: ${color.gray(30)};
  
  .footer-menu{
    ul{
      li{
        a{
          display: inline-block;
          height: 1.5rem;
          color: ${color.gray(80)};
          font-size: 0.825rem;
        }
      }
    }
  }

  .footer-bottom{
    margin-top: 3rem;
  }

  .footer-bottom-logo{
    img{
      width: auto;
      height: 2rem;
    }
  }

  .footer-bottom-copy{
    margin-top: 1rem;
    opacity: 0.6;
    font-size: 0.875rem;
  }


  @media (min-width: ${bp(3)}) {

    padding: 0;

    .footer-wrapper{
      max-width: 75rem;
      padding: 2rem
    }

    .footer-bottom{
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

  }

`