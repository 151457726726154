import React, { useMemo, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {};

// Component
const HeaderMainMenu: React.FC<Props> = (props) => {

  const layout = useLayout();
  const router = useRouter();

  const activeTab = useMemo(() => {

    const homePageTabs = ['/', '/gobi', '/oasis', '/ormuz', '/merchants', '/more'];

    if(homePageTabs.includes(router.asPath)){
      return layout.activeTab === '/' ? 'intro' : layout.activeTab;
    }

  }, [layout.activeTab, router.asPath]);

  return (
    <StyledHeaderMainMenu className="headerMainMenu">

      <ul className="headerMainMenu-menu">
        <li className={activeTab === 'intro' ? 'is-active' : ''} onClick={() => layout.setActiveTab('intro')}>
          <Link shallow={true} href="/" className="headerMainMenu-link">
            Intro
          </Link>
        </li>
        <li className={activeTab === 'gobi' ? 'is-active' : ''} onClick={() => layout.setActiveTab('gobi')}>
          <Link shallow={true} href="/gobi" className="headerMainMenu-link">
            Gobi
          </Link>
        </li>
        <li className={activeTab === 'oasis' ? 'is-active' : ''} onClick={() => layout.setActiveTab('oasis')}>
          <Link shallow={true} href="/oasis" className="headerMainMenu-link">
            Oasis
          </Link>
        </li>
        <li className={activeTab === 'ormuz' ? 'is-active' : ''} onClick={() => layout.setActiveTab('ormuz')}>
          <Link shallow={true} href="/ormuz" className="headerMainMenu-link">
            Ormuz
          </Link>
        </li>
        <li className={activeTab === 'merchants' ? 'is-active' : ''} onClick={() => layout.setActiveTab('merchants')}>
          <Link shallow={true} href="/merchants" className="headerMainMenu-link">
            Merchants
          </Link>
        </li>
        <li className={activeTab === 'more' ? 'is-active' : ''} onClick={() => layout.setActiveTab('more')}>
          <Link shallow={true} href="/more" className="headerMainMenu-link">
            More
          </Link>
        </li>
      </ul>

    </StyledHeaderMainMenu>
  );
};

export default HeaderMainMenu;


const StyledHeaderMainMenu = styled.div<{}>`

  display: block;
  font-size: 1rem;

  .headerMainMenu-menu{
    display: flex;
    gap: 1.5rem;
  }

  li{

    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    height: 2.5rem;
    opacity: 0.75;
    
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 0%;
      height: 1px;
      padding: 0 0.25rem;
      background-color: white;
      opacity: 0;
      transform: translateX(-50%);
      transition: opacity 0.1s ease-out, width 0.2s ease-out;
    }

    &.is-active, &:hover{

      opacity: 1;

      &::after{
        width: 100%;
        opacity: 1;
      }
    }

  }

  @media (min-width: ${bp(3)}) {

  } 

`;
