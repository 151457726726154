import React, { useState, useMemo, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';

type Props = {};

// Component
const HeaderSubMenu: React.FC<Props> = (props) => {

  const router = useRouter();

  const activeTab = useMemo(() => {

    const tabs = ['/gobi-token', '/staking', '/ceremony'];

    if(tabs.includes(router.asPath)){
      return router.asPath.replace('/', '');
    }

  }, [router.asPath]);

  return (
    <StyledHeaderSubMenu className="headerSubMenu">

      <ul className="headerSubMenu-menu">
        <li className={activeTab === 'gobi-token' ? 'is-active' : ''}>
          <Link href="/gobi-token" className="headerSubMenu-link">
            $GOBI
          </Link>
        </li>
        <li className={activeTab === 'staking' ? 'is-active' : ''}>
          <Link href="/staking" className="headerSubMenu-link">
            Staking
          </Link>
        </li>
        <li className={activeTab === 'ceremony' ? 'is-active' : ''}>
          <Link href="/ceremony" className="headerSubMenu-link">
            Ceremony
          </Link>
        </li>
      </ul>

    </StyledHeaderSubMenu>
  );
};

export default HeaderSubMenu;


const StyledHeaderSubMenu = styled.div<{}>`

  display: block;
  font-size: 0.875rem;

  .headerSubMenu-menu{
    display: flex;
    gap: 1.25rem;
  }

  li{

    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    height: 2.5rem;
    opacity: 0.75;
    
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 0%;
      height: 1px;
      padding: 0 0.25rem;
      background-color: white;
      opacity: 0;
      transform: translateX(-50%);
      transition: opacity 0.1s ease-out, width 0.2s ease-out;
    }

    &.is-active, &:hover{

      opacity: 1;

      &::after{
        width: 100%;
        opacity: 1;
      }
    }

  }

  @media (min-width: ${bp(3)}) {

  } 

`;
