import React, { } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';
import HeaderMainMenu from 'components/layout/header/headerMainMenu';
import HeaderSubMenu from 'components/layout/header/headerSubMenu';

import theme, {bp, color} from 'settings/theme';

const Header: React.FC<{}> = (props) => {

  const layout = useLayout();

  return (
    <StyledHeader className="header">

      {/* Fake header for fixed positioning */}
      <div className="header-fake" />

      {/* Fixed header */}
      <div className="header-float">
        <MaxWidthWrapper className="header-wrapper">

          
          <div className="header-left">

            {/* Logo */}
            <div className="header-logo">
              <Link href="/">
                <img src="/images/gobi-logo-main.svg" alt="Gobi Logo" />
              </Link>
            </div>

            {/* Main Menu */}
            <div className="header-mainMenu">
              <HeaderMainMenu />
            </div>

          </div>

          <div className="header-right">

            {/* Sub Menu */}
            <div className="header-subMenu">
              <HeaderSubMenu />
            </div>
            

            {/* Social Buttons */}
            <div className="header-socialButtons">
              <Link href="https://twitter.com/gobi_labs" target="_blank">
                <div className="header-socialButton-inner">
                  <img src="/images/icons/icon-twitter-white.svg" alt="Twitter" />
                </div>
              </Link>
              <Link href="https://discord.gg/gobi" target="_blank">
                <div className="header-socialButton-inner">
                  <img src="/images/icons/icon-discord-white.svg" alt="Tritter" />
                </div>
              </Link>
            </div>

            {/* Hamburger */}
            <div className="header-burger menuButton-ignore">
              <button onClick={() => layout.setIsMainMenuOpen(!layout.isMainMenuOpen)}>
                <div className="header-burger-inner">
                  <img src="/images/icons/icon-hamburger-white.svg" alt="Hamburger" />
                </div>
              </button>
            </div>

          </div>
        </MaxWidthWrapper>
      </div>

    </StyledHeader>
  );

};

export default Header;


// Header
const StyledHeader = styled.header`

  .header-fake{
    height: 3.5rem;
  }

  .header-float{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 3.5rem;
    background-color: rgba(38, 38, 38, 0.75);
    backdrop-filter: blur(5px);
  }

  .header-wrapper{
    display: flex;
    align-items: center;
    height: 100%;
  }

  .header-left {

    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-left: 1rem;

    .header-logo{

      img{
        width: auto;
        height: 2rem;
      }

    }

    .header-mainMenu{
      display: none;
    }
    
  }

  

  .header-right{

    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;

    .header-subMenu{
      display: none;
    }

    .header-socialButtons{

      display: flex;
      align-items: center;

      a{

        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;

        .header-socialButton-inner{

          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          transition: background-color 0.15s ease-out;

          &:hover{
            background-color: ${color.gray(12)};
          }

          img{
            width: 1.125rem;
            height: 1.125rem;
          }

        }

      }

    }

    .header-burger{

      button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.5rem;
        height: 100%;
      }

      .header-burger-inner{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        transition: background-color 0.15s ease-out;
      }

      img{
        width: 1.25rem;
        height: 1.25rem;
      }

      &:hover{

        .header-burger-inner{
          background-color: ${color.gray(12)};
        }

      }

    }

  }

  @media (min-width: ${bp(4)}) {

    .header-fake{
      height: 5rem;
    }

    .header-float{
      height: 5rem;
    }

    .header-left {

      padding-left: 1.5rem;

      .header-mainMenu{
        display: flex;
        margin-left: 2rem;
      }
      
    }

    .header-right {

      margin-right: 1rem;

      .header-subMenu{
        display: flex;  
        margin-right: 2rem;
      }

      .header-burger{
        display: none;
      }

    }

  }


  @media (min-width: ${bp(5)}) {

    .header-fake{
      height: 6rem;
    }

    .header-float{
      height: 6rem;
    }

    .header-left {

      padding-left: 2rem;

      .header-logo{

        img{
          height: 2.4rem;
        }

      }
      
    }

    .header-right {
      margin-right: 2rem;
    }

  }


`